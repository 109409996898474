<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="height"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>

        <!-- <el-table-column label="" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.id }}</template>
</el-table-column> -->
        <el-table-column label="产品编号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.systemId }}</template>
        </el-table-column>
        <el-table-column label="产品名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <!-- <el-table-column label="分类编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryCode }}</template>
        </el-table-column> -->
        <el-table-column label="分类名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column label="标题" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <!-- <el-table-column label="描述" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column> -->
        <el-table-column label="封面图片" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image
              style="width: 80px; height: 80px"
              :preview-src-list="scope.row.coverImage.split(',')"
              :src="scope.row.coverImage"
            >
            </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="是否可见(0:否;1:是)"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.isVisible }}</template>
        </el-table-column> -->
        <el-table-column label="排序" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.seq }}</template>
        </el-table-column>
        <el-table-column label="风格" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.style }}</template>
        </el-table-column>
        <el-table-column label="房型" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.houseType }}</template>
        </el-table-column>
        <el-table-column label="价格" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <!-- <el-table-column
          label="创建人编码"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.createBy }}</template>
        </el-table-column> -->
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row.productCode, scope.row.id)"
              size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="layerManage(scope.row.productCode)"
              style="margin-left: 0"
              >图层</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.productCode)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="80%"
      class="mainPublishDialog"
      @close="bulletBoxClose"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        :key="timer"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="产品名称：" prop="siteName">
              <el-input
                v-model="form.productName"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="siteName">
              <el-cascader
                @change="changeProduct"
                v-model="form.categoryCode"
                style="width: 100%"
                :options="productList"
                :props="{
                  expandTrigger: 'click',
                  value: 'categoryCode',
                  label: 'categoryName',
                  children: 'children',
                  checkStrictly: true,
                }"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标题：" prop="siteName">
              <el-input
                v-model="form.title"
                placeholder="请输入标题名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序：" prop="siteName">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="风格：" prop="siteName">
              <el-input
                v-model="form.style"
                placeholder="请输入风格"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房型：" prop="siteName">
              <el-input
                v-model="form.houseType"
                placeholder="请输入房型"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格：" prop="siteName">
              <el-input
                v-model="form.price"
                type="number"
                placeholder="请输入价格"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转产品编号：" prop="skipSystemId">
              <el-input
                v-model="form.skipSystemId"
                placeholder="请输入跳转产品编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图片：" prop="siteName">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="true"
                :on-success="handleSuccess"
              >
                <img
                  v-show="form.coverImage"
                  :src="form.coverImage"
                  class="avatar"
                />
                <div v-show="!form.coverImage">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="labelType === 'edit'">
        <el-col
          :span="24"
          style="height: 35px; width: 100%; font-size: 18px; line-height: 35px"
        >
          详细信息：
        </el-col>
        <el-col :span="24" style="margin-left: 35px">
          <!-- <el-button
            type="primary"
            @click="increaseClass"
            style="margin-bottom: 8px; margin-left: 3px"
            >添加分类</el-button
          >
          <el-button
            type="primary"
            @click="editTabs"
            v-if="!isEditTabs"
            style="margin-bottom: 8px; margin-left: 3px"
          >
            修改分类
          </el-button>
          <el-button
            type="primary"
            @click="editTabsComplete"
            v-if="isEditTabs"
            style="margin-bottom: 8px; margin-left: 3px"
            >确定修改
          </el-button> -->
          <div>
            <div>
              <el-button
                type="primary"
                @click="increaseClass"
                style="margin-bottom: 8px; margin-left: 3px"
                >添加分类</el-button
              >
              <el-button
                type="primary"
                @click="editTabs"
                v-if="!isEditTabs"
                style="margin-bottom: 8px; margin-left: 3px"
              >
                修改分类
              </el-button>
              <el-button
                type="primary"
                @click="editTabsComplete"
                v-if="isEditTabs"
                style="margin-bottom: 8px; margin-left: 3px"
                >确定修改
              </el-button>
            </div>
            <div v-if="isShowflags">
              <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="起始位置">
                  <el-input
                    v-model="rectangleForm.x"
                    style="width: 200px !important"
                  ></el-input>
                  <el-input
                    v-model="rectangleForm.y"
                    style="width: 200px !important"
                  ></el-input>
                </el-form-item>
                <el-form-item label="矩形占比">
                  <el-input
                    v-model="rectangleForm.width"
                    style="width: 200px !important"
                  ></el-input>
                  <el-input
                    v-model="rectangleForm.height"
                    style="width: 200px !important"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <el-tabs
            ref="tabs"
            :tab-position="tabPosition"
            v-model="editableTabsValue"
            type="card"
            :closable="isEditTabs"
            @tab-click="clickTab"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              :key="item.attrCode"
              v-for="item in editableTabs"
              :label="item.attrName"
              :name="item.attrType"
            >
              <template slot="label">
                <el-input
                  class="tabsInput"
                  v-model="item.attrName"
                  v-if="isEditTabs"
                  @input="inputChange"
                ></el-input>
              </template>
              <!-- 短视频 -->
              <template v-if="item.attrType === '1'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <el-checkbox
                      v-model="videoCheckAll"
                      @change="videoCheckAllChange"
                    ></el-checkbox>
                    <el-button type="text" @click="videoBatchDelete"
                      >批量删除</el-button
                    >
                    <el-button type="text" @click="videoBtn"
                      ><i class="el-icon-upload2"></i> 上传
                    </el-button>
                  </div>
                  <div
                    class="tabPanOnebtm"
                    style="width: 100%; height: 300px; overflow-y: auto"
                  >
                    <ul v-if="coverVideoList.length > 0">
                      <li v-for="item in coverVideoList" :key="item.id">
                        <el-checkbox
                          v-if="videoCheckAll"
                          v-model="item.checked"
                        ></el-checkbox>
                        <video
                          class="video"
                          controls
                          :src="item.itemFileUrl"
                        ></video>
                      </li>
                    </ul>
                    <el-empty v-else :image-size="180"></el-empty>
                    <div></div>
                  </div>
                </div>
              </template>
              <!-- VR -->
              <template v-else-if="item.attrType === '2'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <el-button type="text" @click="vrBtn"
                      ><i class="el-icon-upload2"></i> 上传
                    </el-button>
                  </div>
                  <div class="tabPanTwobtm">
                    <div style="display: flex; width: 300px; margin-left: 30px">
                      VR资源地址：
                      <el-input
                        :disabled="true"
                        v-model="vrShowForm.itemFileUrl"
                        placeholder="资源地址"
                        style="width: 70%"
                      ></el-input>
                    </div>
                    <div class="vrImg">
                      封面图片：
                      <img
                        v-if="vrShowForm.showImage"
                        :src="vrShowForm.showImage"
                        alt=""
                      />
                      <el-empty v-else :image-size="150"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 全屋空间 -->
              <template v-else-if="item.attrType === '4'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <div style="width: 85%">
                      <el-form style="margin-top: 20px; width: 260px">
                        <el-form-item label="效果图：" label-width="100px">
                          <el-select
                            ref="selsect"
                            v-model="effectName"
                            placeholder="请选择"
                            size="mini"
                            @change="allHouseChange"
                          >
                            <el-option
                              v-for="item in effectList"
                              :key="item.itemCode"
                              :label="item.itemName"
                              :value="item.itemCode"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>

                    <el-checkbox
                      v-model="houseCheckAll"
                      @change="houseCheckAllChange"
                    ></el-checkbox>
                    <el-button type="text" @click="houseBatchDelete"
                      >批量删除</el-button
                    >
                    <el-button type="text" @click="houseBtn"
                      ><i class="el-icon-upload2"></i> 上传
                    </el-button>
                  </div>
                  <div class="tabPanThreebtm">
                    <!-- <el-tabs type="card" v-model="houseName" @tab-click="houseTabs">
                      <el-tab-pane
                        v-for="(item, index) in houseList"
                        :label="item.dictName"
                        :key="index"
                        :name="item.dictValue"
                        > -->

                    <div style="width: 100%; height: 220px; overflow-y: auto">
                      <ul class="tabPanUl" v-if="houseImageList.length > 0">
                        <li
                          v-for="(item, index) in houseImageList"
                          :key="index"
                        >
                          <el-checkbox
                            v-if="houseCheckAll"
                            v-model="item.checked"
                          ></el-checkbox>
                          <img :src="item.itemFileUrl" alt="" />
                        </li>
                      </ul>
                      <el-empty v-else :image-size="150"></el-empty>
                    </div>
                    <!-- </el-tab-pane
                  >
                </el-tabs> -->
                  </div>
                </div>
              </template>
              <!-- 效果图 -->
              <template v-else-if="item.attrType === '3'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <el-checkbox
                      v-model="renderCheckAll"
                      @change="renderCheckAllChange"
                    ></el-checkbox>
                    <el-button type="text" @click="renderBatchDelete"
                      >批量删除</el-button
                    >
                    <el-button type="text" @click="renderBtn"
                      ><i class="el-icon-upload2"></i> 上传
                    </el-button>
                  </div>
                  <div class="tabPanThreebtm">
                    <!-- <el-tabs type="card" v-model="renderName"  @tab-click="renderTabs">
                      <el-tab-pane
                        v-for="(item, index) in renderList"
                        :label="item.dictName"
                        :key="index"
                        :name="item.dictValue"
                        > -->
                    <div style="width: 100%; overflow-y: auto">
                      <ul class="tabPanUl" v-if="renderImageList.length > 0">
                        <li
                          v-for="(item, index) in renderImageList"
                          :key="index"
                        >
                          <el-checkbox
                            v-if="renderCheckAll"
                            v-model="item.checked"
                          ></el-checkbox>
                          <img :src="item.itemFileUrl" alt="" />
                          <div style="text-align: center; width: 100%">
                            {{ item.itemName }}
                          </div>
                        </li>
                      </ul>
                      <el-empty v-else :image-size="150"></el-empty>
                    </div>
                    <!-- </el-tab-pane
                  >
                </el-tabs> -->
                  </div>
                </div>
              </template>
              <!-- 柜体 -->
              <template v-else-if="item.attrType === '5'">
                <div style="width: 90%; position: relative">
                  <div class="middelTop">
                    <h2>{{ cabinetFace.cabinetMainTitle }}</h2>
                    <div class="icon">
                      <div>
                        <el-button
                          class="btn"
                          type="text"
                          @click="cabinetShowBtn"
                          ><i class="el-icon-upload2"></i>
                        </el-button>
                        <!-- <i @click="cabinetShowBtn" class="el-icon-upload2"></i> -->
                      </div>
                      <span>色卡</span>
                      <div>
                        <!-- <i @click="cabinetFileBtn" class="el-icon-upload2"></i> -->
                        <el-button
                          class="btn"
                          type="text"
                          @click="cabinetFileBtn"
                          ><i class="el-icon-upload2"></i>
                        </el-button>
                        <el-button
                          class="btn"
                          type="text"
                          style="margin-left: 5px"
                          @click="canbinetBtnDele"
                          ><i class="el-icon-delete"></i
                        ></el-button>
                      </div>
                    </div>
                  </div>
                  <div class="cabinetMiddle">
                    <div class="middleLeft">
                      <div class="middleImg">
                        <img
                          v-if="cabinetFace.cabinetMainImage"
                          width="100%"
                          height="100%"
                          :src="cabinetFace.cabinetMainImage"
                          alt=""
                        />
                        <el-empty v-else :image-size="150"></el-empty>
                      </div>
                    </div>

                    <div class="middleRight" style="overflow-y: auto">
                      <div class="rightOne">
                        <ul v-if="cabinetFace.colorImageList.length > 0">
                          <li
                            v-for="(item, index) in cabinetFace.colorImageList"
                            :key="index"
                          >
                            <img
                              class="img"
                              width="100%"
                              height="100%"
                              :src="item.showImage"
                              alt=""
                              :title="item.itemName"
                            />
                          </li>
                        </ul>
                        <el-empty v-else :image-size="50"></el-empty>
                      </div>
                      <div class="rightTwo">
                        <ul v-if="cabinetFace.colorImageList.length > 0">
                          <li
                            v-for="(item, index) in cabinetFace.colorImageList"
                            :key="index"
                            @click="canbinetChangeImg(item)"
                          >
                            <img
                              class="img"
                              width="100%"
                              height="100%"
                              :src="item.itemFileUrl"
                              alt=""
                              :title="item.itemName"
                            />
                            <div class="detele" v-if="canbinDeleImg">
                              <el-popconfirm
                                title="这是一段内容确定删除吗？"
                                @confirm="canbinetSureDele(item)"
                              >
                                <el-button class="deleBtn" slot="reference"
                                  ><i class="el-icon-close"></i
                                ></el-button>
                              </el-popconfirm>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="cabinetBottom"
                    style="width: 100%; height: 100px; overflow-x: auto"
                  >
                    <ul
                      style="margin: 0"
                      v-if="cabinetFace.cabinetImageList.length > 0"
                    >
                      <li
                        v-for="(item, index) in cabinetFace.cabinetImageList"
                        :key="index"
                        @click="switchImg(item, index)"
                      >
                        <img
                          class="img"
                          width="100%"
                          height="90"
                          :src="item.itemFileUrl"
                          alt=""
                        />
                      </li>
                    </ul>
                    <el-empty v-else :image-size="40"></el-empty>
                  </div>
                </div>
              </template>
              <!-- 内部 -->
              <template v-else-if="item.attrType === '6'">
                <div style="width: 90%">
                  <div class="newInternalTop">
                    <div class="topOne" style="width: 260px">
                      <el-form
                        :model="internalForm"
                        style="margin-top: 10px; width: 260px"
                      >
                        <el-form-item label="柜体：">
                          <el-select
                            ref="selsect"
                            v-model="internalForm.internalName"
                            placeholder="请选择"
                            size="mini"
                            @change="interSelsec"
                          >
                            <el-option
                              v-for="item in internalForm.internalNameList"
                              :key="item.itemCode"
                              :label="item.itemName"
                              :value="item.itemCode"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>

                      <!-- <el-select
                      ref="selsect"
                      v-model="internalForm.internalName"
                      placeholder="请选择"
                      size="mini"
                      @change="interSelsec"
                    >
                      <el-option
                        v-for="item in internalForm.internalNameList"
                        :key="item.itemCode"
                        :label="item.itemName"
                        :value="item.itemCode"
                      >
                      </el-option>
                    </el-select> -->
                    </div>
                    <div class="topTwo">
                      <h2>{{ internalForm.internalMainTitle }}</h2>
                    </div>
                    <div class="topThree">
                      <el-button
                        class="btn"
                        type="text"
                        @click="startDrawingMode"
                        :disabled="drawingMode"
                        >开始绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="saveRectangles(internalAttrCode)"
                        :disabled="!drawingMode"
                        >确认绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="cancelDrawing"
                        :disabled="!drawingMode"
                        >取消绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="showButtonFlag"
                        @click="showDrawing(internalAttrCode, 'internal_')"
                        >显示矩形
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="!showButtonFlag"
                        @click="cancelDrawing"
                        >隐藏矩形</el-button
                      >
                      <el-button class="btn" type="text" @click="internalBtn"
                        ><i class="el-icon-upload2"></i> 上传
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="internalBtnDele"
                        ><i class="el-icon-delete"></i> 删除
                      </el-button>
                    </div>
                  </div>
                  <div class="newInternalMiddle">
                    <div class="middleLeft">
                      <!-- <img width="100%" height="100%" src="https://api.dianbianjia.com/resources/upload/file_202306213605107072276.jpg" alt="" /> -->
                      <img
                        ref="internal_image"
                        class="interImage"
                        v-if="internalForm.internalMain"
                        :src="internalForm.internalMain"
                        width="100%"
                        height="100%"
                        alt=""
                        @load="onImageLoad('internal_')"
                        style="position: relative; z-index: 1"
                      />
                      <el-empty v-else :image-size="230"></el-empty>
                      <img
                        ref="internal_floatingImage"
                        :src="internalForm.internalShowImg"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          opacity: 1;
                          z-index: 0;
                        "
                        v-if="false"
                      />
                      <div
                        ref="internal_floatingImageList"
                        class="interSmallImage"
                        v-for="(image, index) in floatingImageList"
                        :key="index"
                      >
                        <img
                          :src="image.url"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 1;
                            z-index: 2;
                          "
                          v-if="showFloatingImage"
                        />
                      </div>
                      <canvas
                        ref="internal_canvas"
                        @mousedown="startDrawing"
                        @mouseup="stopDrawing"
                        @mousemove="drawRectangle"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                        "
                      ></canvas>
                    </div>

                    <div
                      class="middleRight"
                      style="width: 13%; height: 66%; overflow-y: scroll"
                    >
                      <ul style="margin: 0">
                        <li
                          v-for="(item, index) in internalForm.internalMainList"
                          :key="index"
                          @click="changeRightImg(item)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="100%"
                            :src="item.itemFileUrl"
                            alt=""
                          />

                          <div class="detele" v-if="deleImage">
                            <el-popconfirm
                              title="这是一段内容确定删除吗？"
                              @confirm="sureDele(item)"
                            >
                              <el-button class="deleBtn" slot="reference"
                                ><i class="el-icon-close"></i
                              ></el-button>
                            </el-popconfirm>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="internalBottom">
                    <div
                      class="bottomBox"
                      style="width: 100%; height: 100px; overflow-x: scroll"
                    >
                      <ul
                        style="margin: 0"
                        v-if="internalForm.internalSmallList.length > 0"
                      >
                        <li
                          ref="interIl"
                          v-for="(
                            item, index
                          ) in internalForm.internalSmallList"
                          :key="index"
                          @click="switchInternalImg(item, index)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="90"
                            :src="item.itemFileUrl"
                            alt=""
                          />
                        </li>
                      </ul>
                      <el-empty v-else :image-size="40"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 柜门 -->
              <template v-else-if="item.attrType === '7'">
                <div style="width: 90%">
                  <div class="newInternalTop">
                    <div class="topTwo" style="flex: 1; text-align: center">
                      <!--                      <h2>{{ cabDoorFaceForm.cabDoorMainTitle }}</h2>-->
                    </div>
                    <div class="topThree">
                      <el-upload
                        class="upload-demo"
                        :action="$uploadURL"
                        :on-success="handleCabDoorMainSuccess"
                        :before-upload="beforeCabDoorMainUpload"
                        :show-file-list="false"
                      >
                        <el-button class="btn" type="text"
                          >上传门板图</el-button
                        >
                      </el-upload>
                      <el-button
                        class="btn"
                        type="text"
                        @click="startDrawingMode"
                        :disabled="drawingMode"
                        >开始绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="saveRectangles(cabDoorAttrCode)"
                        :disabled="!drawingMode"
                        >确认绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="cancelDrawing"
                        :disabled="!drawingMode"
                        >取消绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="showButtonFlag"
                        @click="showDrawing(cabDoorAttrCode, 'cabDoor_')"
                        >显示矩形
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="!showButtonFlag"
                        @click="cancelDrawing"
                        >隐藏矩形</el-button
                      >
                      <el-button class="btn" type="text" @click="cabDoorBtn"
                        ><i class="el-icon-upload2"></i> 上传
                      </el-button>
                      <el-button class="btn" type="text" @click="cabdoorBtnDele"
                        ><i class="el-icon-delete"></i> 删除
                      </el-button>
                    </div>
                  </div>
                  <div class="newInternalMiddle">
                    <div class="middleLeft">
                      <img
                        ref="cabDoor_image"
                        class="interImage"
                        v-if="form.prcture"
                        :src="form.prcture"
                        width="100%"
                        height="100%"
                        alt=""
                        @load="onImageLoad('cabDoor_')"
                        style="position: relative; z-index: 1"
                      />
                      <el-empty v-else :image-size="150"></el-empty>
                      <img
                        ref="cabDoor_floatingImage"
                        :src="cabDoorFaceForm.cabDoorShowImg"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          opacity: 1;
                          z-index: 0;
                        "
                        v-if="false"
                      />
                      <div
                        ref="cabDoor_floatingImageList"
                        class="interSmallImage"
                        v-for="(image, index) in floatingImageList"
                        :key="index"
                      >
                        <img
                          :src="image.url"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 1;
                            z-index: 2;
                          "
                          v-if="showFloatingImage"
                        />
                      </div>
                      <canvas
                        ref="cabDoor_canvas"
                        @mousedown="startDrawing"
                        @mouseup="stopDrawing"
                        @mousemove="drawRectangle"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                        "
                      ></canvas>
                    </div>

                    <div
                      class="middleRight"
                      style="width: 13%; height: 66%; overflow-y: scroll"
                    >
                      <ul
                        style="margin: 0"
                        v-if="cabDoorFaceForm.cabImgList.length > 0"
                      >
                        <li
                          v-for="(item, index) in cabDoorFaceForm.cabImgList"
                          :key="index"
                          @click="cabDoorChangeShow(item)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="100%"
                            :src="item.itemFileUrl"
                            alt=""
                          />
                          <div class="detele" v-if="cabdoorDeleImg">
                            <el-popconfirm
                              title="这是一段内容确定删除吗？"
                              @confirm="cabdoorSureDele(item)"
                            >
                              <el-button class="deleBtn" slot="reference"
                                ><i class="el-icon-close"></i
                              ></el-button>
                            </el-popconfirm>
                          </div>
                        </li>
                      </ul>
                      <el-empty v-else :image-size="80"></el-empty>
                    </div>
                  </div>
                  <div class="internalBottom">
                    <div
                      class="bottomBox"
                      style="width: 100%; height: 100px; overflow-x: scroll"
                    >
                      <ul
                        style="margin: 0"
                        v-if="cabDoorFaceForm.cabSmallImgList.length > 0"
                      >
                        <li
                          v-for="(
                            item, index
                          ) in cabDoorFaceForm.cabSmallImgList"
                          :key="index"
                          @click="switchCabImg(item, index)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="90"
                            :src="item.itemFileUrl"
                            alt=""
                          />
                        </li>
                      </ul>
                      <el-empty v-else :image-size="40"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 新增 -->
              <template v-else>
                <div style="width: 90%">
                  <div class="newInternalTop">
                    <div class="topTwo" style="flex: 1; text-align: center">
                      <h2>{{ addClassFaceForm.addClassMainTitle }}</h2>
                    </div>
                    <div class="topThree">
                      <el-upload
                        class="upload-demo"
                        :action="$uploadURL"
                        :on-success="handleCabDoorMainSuccess1"
                        :before-upload="beforeCabDoorMainUpload"
                        :show-file-list="false"
                        v-if="Number(item.attrType) == 8"
                      >
                        <el-button class="btn" type="text"
                          >上传门板图</el-button
                        >
                      </el-upload>
                      <el-button
                        class="btn"
                        type="text"
                        @click="startDrawingMode"
                        :disabled="drawingMode"
                        >开始绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="saveRectangles(addClassAttrCode)"
                        :disabled="!drawingMode"
                        >确认绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="cancelDrawing"
                        :disabled="!drawingMode"
                        >取消绘制
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="showButtonFlag"
                        @click="showDrawing(addClassAttrCode, 'addClass_')"
                        >显示矩形
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        v-if="!showButtonFlag"
                        @click="cancelDrawing"
                        >隐藏矩形</el-button
                      >
                      <el-button class="btn" type="text" @click="addClassBtn"
                        ><i class="el-icon-upload2"></i> 上传
                      </el-button>
                      <el-button
                        class="btn"
                        type="text"
                        @click="addclassBtnDele"
                        ><i class="el-icon-delete"></i> 删除
                      </el-button>
                    </div>
                  </div>
                  <div class="newInternalMiddle">
                    <div class="middleLeft">
                      <img
                        ref="addClass_image"
                        class="interImage"
                        v-if="addClassFaceForm.addClassMain"
                        :src="addClassFaceForm.addClassMain"
                        width="100%"
                        height="100%"
                        alt=""
                        @load="onImageLoad('addClass_')"
                        style="position: relative; z-index: 1"
                      />
                      <el-empty v-else :image-size="150"></el-empty>
                      <img
                        ref="addClass_floatingImage"
                        :src="addClassFaceForm.addClassShowImg"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          opacity: 1;
                          z-index: 0;
                        "
                        v-if="false"
                      />
                      <div
                        ref="addClass_floatingImageList"
                        class="interSmallImage"
                        v-for="(image, index) in floatingImageList"
                        :key="index"
                      >
                        <img
                          :src="image.url"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 1;
                            z-index: 2;
                          "
                          v-if="showFloatingImage"
                        />
                      </div>
                      <canvas
                        ref="addClass_canvas"
                        @mousedown="startDrawing"
                        @mouseup="stopDrawing"
                        @mousemove="drawRectangle"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                        "
                      ></canvas>
                    </div>

                    <div
                      class="middleRight"
                      style="width: 13%; height: 66%; overflow-y: scroll"
                    >
                      <ul
                        style="margin: 0"
                        v-if="addClassFaceForm.addClassRightImgList.length > 0"
                      >
                        <li
                          v-for="(
                            item, index
                          ) in addClassFaceForm.addClassRightImgList"
                          :key="index"
                          @click="addclassChangeShow(item)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="100%"
                            :src="item.itemFileUrl"
                            alt=""
                          />
                          <div class="detele" v-if="addClassDeleImg">
                            <el-popconfirm
                              title="这是一段内容确定删除吗？"
                              @confirm="addclassSureDele(item)"
                            >
                              <el-button class="deleBtn" slot="reference"
                                ><i class="el-icon-close"></i
                              ></el-button>
                            </el-popconfirm>
                          </div>
                        </li>
                      </ul>
                      <el-empty v-else :image-size="80"></el-empty>
                    </div>
                  </div>
                  <div class="internalBottom">
                    <div
                      class="bottomBox"
                      style="width: 100%; height: 100px; overflow-x: scroll"
                    >
                      <ul
                        style="margin: 0"
                        v-if="addClassFaceForm.addClassBtmImgList.length > 0"
                      >
                        <li
                          v-for="(
                            item, index
                          ) in addClassFaceForm.addClassBtmImgList"
                          :key="index"
                          @click="addclassCabImg(item, index)"
                        >
                          <img
                            class="img"
                            width="100%"
                            height="90"
                            :src="item.itemFileUrl"
                            alt=""
                          />
                        </li>
                      </ul>
                      <el-empty v-else :image-size="40"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </div>
      <span slot="footer">
        <el-button size="small" @click="homePageClose">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 添加分类 -->
    <el-dialog
      title="添加分类"
      :visible.sync="increaseDialog"
      width="30%"
      destroy-on-close
    >
      <el-form :data="vrForm">
        <el-form-item label="名称：" label-width="120px">
          <el-input
            v-model="increaseForm.increaseName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="increaseClose">取 消</el-button>
        <el-button type="primary" @click="increaseSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 短视频上传表单 -->
    <el-dialog
      title="短视频上传"
      :visible.sync="videoDialog"
      width="500px"
      destroy-on-close
    >
      <el-form :data="videoForm" label-width="120px">
        <!-- <el-form-item label="短视频分类：">
          <el-select v-model="videoForm.videoCategoryCode" placeholder="请选择" style="width: 100%;" @change="chooseName"  ref="category" >
          <el-option
            v-for="item in videoOptions"
            :key="item.videoCategoryCode"
            :label="item.videoCategoryName"
            :value="item.videoCategoryCode">
          </el-option>
        </el-select>
        </el-form-item> -->
        <el-form-item label="短视频名称：">
          <el-input
            v-model="videoForm.itemName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="短视频标题：">
          <el-input
            v-model="videoForm.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="短视频排序：">
          <el-input v-model="videoForm.seq" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="短视频上传：">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="videoSuccess"
            :limit="1"
            :on-remove="removeVideo"
            :on-change="videohandleChange"
            ref="picUpload"
          >
            <video
              v-if="videoForm.itemFileUrl"
              class="avatarVideo"
              :src="videoForm.itemFileUrl"
            ></video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="封面图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="videoImageSuccess"
          >
            <img
              v-show="videoForm.showImage"
              :src="videoForm.showImage"
              class="avatar"
            />
            <div v-show="!videoForm.showImage">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoClose">取 消</el-button>
        <el-button type="primary" @click="videoSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- vr地址 -->
    <el-dialog
      title="VR上传"
      :visible.sync="vrDialog"
      width="30%"
      destroy-on-close
    >
      <el-form :data="vrForm">
        <el-form-item label="VR资源地址：" label-width="120px">
          <el-input
            v-model="vrForm.itemFileUrl"
            placeholder="请输入地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="vrSuccess"
          >
            <img
              v-show="vrForm.showImage"
              :src="vrForm.showImage"
              class="avatar"
            />
            <div v-show="!vrForm.showImage">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="vrClose">取 消</el-button>
        <el-button type="primary" @click="vrSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 全屋空间 -->
    <el-dialog
      title="全屋空间上传"
      :visible.sync="houseDialog"
      width="800px"
      destroy-on-close
    >
      <el-form ref="houseForm" :model="houseForm" label-width="150px">
        <el-form-item label="效果图：">
          <el-select
            v-model="houseForm.parentCode"
            placeholder="请选择效果图"
            @change="houseSelect"
          >
            <el-option
              v-for="item in effectList"
              :key="item.itemCode"
              :label="item.itemName"
              :value="item.itemCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="housePreviewImg"
            :on-remove="houseRemoveImg"
            :on-success="houseUploadImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="houseIsPreview" :modal="false">
        <el-image width="100%" :src="housePreviewItem" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="houseClose">取 消</el-button>
        <el-button type="primary" @click="houseSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 效果图 -->
    <el-dialog
      title="效果图上传"
      :visible.sync="renderDialog"
      width="800px"
      destroy-on-close
    >
      <el-form ref="houseForm" :model="renderForm" label-width="120px">
        <!-- <el-form-item label="效果图分类：">
          <el-select v-model="renderForm.itemType" placeholder="请选择名称" @change="renderSelect">
            <el-option
              v-for="item in renderOptions"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="renderPreviewImg"
            :on-remove="renderRemoveImg"
            :on-success="renderUploadImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="renderIsPreview" :modal="false">
        <el-image width="100%" :src="renderPreviewItem" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renderClose">取 消</el-button>
        <el-button type="primary" @click="renderSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 柜体1 -->
    <el-dialog
      title="色卡主图上传"
      :visible.sync="cabinetFileDialog"
      width="800px"
      destroy-on-close
    >
      <el-form :data="cabinetForm">
        <el-form-item label="主图上传：" label-width="120px">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeImg"
            :on-success="uploadImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cabinetClose">取 消</el-button>
        <el-button type="primary" @click="cabinetSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 柜体2 -->
    <el-dialog
      title="色卡展示图上传"
      :visible.sync="cabinetShowDialog"
      width="800px"
      destroy-on-close
    >
      <el-form :data="cabinetShowForm">
        <el-form-item label="展示图上传：" label-width="120px">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeShowImg"
            :on-success="uploadShowImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cabinetShowClose">取 消</el-button>
        <el-button type="primary" @click="cabinetShowSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 柜体编辑图片 -->
    <el-dialog
      title="编辑图片"
      :visible.sync="canbinetImgDialog"
      width="30%"
      destroy-on-close
      v-if="!canbinDeleImg"
    >
      <el-form :data="cabinetImgform">
        <el-form-item label="图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploaderImage"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="canbinChangeImgSuccess"
          >
            <img
              v-show="cabinetImgform.itemFileUrl"
              :src="cabinetImgform.itemFileUrl"
              class="avatarImage"
            />
            <div v-show="!cabinetImgform.itemFileUrl">
              <i class="el-icon-plus avatar-uploaderImage-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canbinImgClose">取 消</el-button>
        <el-button type="primary" @click="canbinImgSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 内部 -->
    <el-dialog
      title="内部图片上传"
      :visible.sync="internalDialog"
      width="800px"
      destroy-on-close
    >
      <el-form :data="interForm">
        <el-form-item label="柜体分类" label-width="120px">
          <el-select
            v-model="interForm.internalName"
            placeholder="请选择"
            size="mini"
            @change="internalSelsec"
          >
            <el-option
              v-for="item in interForm.internalNameList"
              :key="item.itemCode"
              :label="item.itemName"
              :value="item.itemCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片上传：" label-width="120px">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeInternalImg"
            :on-success="uploadInternalImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="internalClose">取 消</el-button>
        <el-button type="primary" @click="internalSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 内部编辑图片 -->
    <el-dialog
      title="编辑图片"
      :visible.sync="interImgDialog"
      width="30%"
      destroy-on-close
      v-if="!deleImage"
    >
      <el-form :data="interImgform">
        <el-form-item label="图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploaderImage"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="interChangeImgSuccess"
          >
            <img
              v-show="interImgform.itemFileUrl"
              :src="interImgform.itemFileUrl"
              class="avatarImage"
            />
            <div v-show="!interImgform.itemFileUrl">
              <i class="el-icon-plus avatar-uploaderImage-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="interImgClose">取 消</el-button>
        <el-button type="primary" @click="interImgSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 柜门 -->
    <el-dialog
      title="内部图片上传"
      :visible.sync="cabDoorDialog"
      width="800px"
      destroy-on-close
    >
      <el-form :data="cabDoorForm">
        <el-form-item label="图片上传：" label-width="120px">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeCabdoorImg"
            :on-success="uploadCabdoorImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cabdoorClose">取 消</el-button>
        <el-button type="primary" @click="cabdoorSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 柜门编辑图片 -->
    <el-dialog
      title="编辑图片"
      :visible.sync="cabdoorImgDialog"
      width="30%"
      destroy-on-close
      v-if="!cabdoorDeleImg"
    >
      <el-form :data="cabDoorImgform">
        <el-form-item label="图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploaderImage"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="cabdoorChangeImgSuccess"
          >
            <img
              v-show="cabDoorImgform.itemFileUrl"
              :src="cabDoorImgform.itemFileUrl"
              class="avatarImage"
            />
            <div v-show="!cabDoorImgform.itemFileUrl">
              <i class="el-icon-plus avatar-uploaderImage-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cabdoorImgClose">取 消</el-button>
        <el-button type="primary" @click="cabdoorImgSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增分类 -->
    <el-dialog
      title="图片上传"
      :visible.sync="addClassDialog"
      width="800px"
      destroy-on-close
    >
      <el-form :data="addClassForm">
        <el-form-item label="图片上传：" label-width="120px">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeAddClassImg"
            :on-success="uploadAddClassImg"
            :limit="fileLimit"
            :on-exceed="uploadOverLimit"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassClose">取 消</el-button>
        <el-button type="primary" @click="addClassSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增图片编辑 -->
    <el-dialog
      title="编辑图片"
      :visible.sync="addClassImgDialog"
      width="30%"
      destroy-on-close
      v-if="!addClassDeleImg"
    >
      <el-form :data="addClassImgform">
        <el-form-item label="图片：" prop="coverImage" label-width="120px">
          <el-upload
            class="avatar-uploaderImage"
            :action="$uploadURL"
            :show-file-list="true"
            :on-success="addclassChangeImgSuccess"
          >
            <img
              v-show="addClassImgform.itemFileUrl"
              :src="addClassImgform.itemFileUrl"
              class="avatarImage"
            />
            <div v-show="!addClassImgform.itemFileUrl">
              <i class="el-icon-plus avatar-uploaderImage-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addImgClose">取 消</el-button>
        <el-button type="primary" @click="addImgSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图层弹框 -->
    <el-dialog title="图层排序" :visible.sync="layerDialog" width="600px">
      <el-row>
        <!-- <el-form>
            <el-col :span="24" v-for="(item,index) in layerDetail" :key="index">
               <el-form-item :label="item.attrName + '：' " label-width="120px">
                    <el-input v-model="item.seq"></el-input>
               </el-form-item>
            </el-col>
        </el-form> -->
        <el-table :data="layerDetail" stripe border="" style="width: 100%">
          <el-table-column prop="attrName" label="属性名" width="">
          </el-table-column>
          <el-table-column prop="address" label="排序">
            <template slot-scope="scope">
              <el-input v-model="scope.row.seq"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="saveLayer(scope.row)" size="small"
                >保存</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div class="layerTips">提示：排序数字越大，显示图层层级越高</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowflags: false,
      rectangleForm: {},
      layerDetail: [], //图层管理
      layerDialog: false,
      isEditTabs: false, //分类标签是否可编辑
      delIdList: [],
      momey: "",
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        productName: "", // 产品名称
        categoryCode: "", // 分类名称
        title: "", // 标题
        seq: "", // 排序
        itemFileUrl: "", // 封面图片
        style: "", // 风格
        houseType: "", // 房型
        price: "", // 价格
        skipSystemId: "", //跳转名称
        prcture: "", //柜门背景图
      },
      // 短视频
      videoForm: {
        itemName: "",
        title: "",
        seq: "",
        attrCode: "",
        itemFileUrl: "",
        showImage: "",
        isPortraitScreen: "",
      },
      videoOptions: [],
      videoAttrCode: "", // 短视频attrCode
      coverVideoList: [], // 短视频列表
      // vr
      vrForm: {
        itemFileUrl: "",
        attrCode: "",
        showImage: "",
        id: "",
      },
      vrAttrCode: "", // vrAttrCode
      // vr回显
      vrShowForm: {
        itemFileUrl: "",
        showImage: "",
      },
      // 全屋空间
      houseForm: {
        itemType: "", // 全屋空间分类名称
        fileList: [], // 分类图片
        attrCode: "",
      },
      houseAttrCode: "", // 全屋空间AttrCode
      // 效果图
      renderForm: {
        itemType: "", // 效果图名称
        fileList: [], // 效果图
        attrCode: "",
      },
      renderAttrCode: "", // 效果图AttrCode
      // 柜体1
      cabinetForm: {
        attrCode: "",
        fileList: [],
      },
      cabinetAttrCode: "", // 柜体AttrCode
      // 柜体2
      cabinetShowForm: {
        attrCode: "",
        fileList: [],
      },
      // 柜体展示
      cabinetFace: {
        attrCode: "",
        cabinetMainTitle: "", // 标题
        cabinetMainImage: "", // 主图
        cabinetImageList: [], // 底部小图
        colorImageList: [], // 右侧图片
      },
      // 柜体编辑图片
      cabinetImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },
      // 内部
      internalForm: {
        internalName: "", // 选中的名字
        parentCode: "", // 选中的code
        productCode: "",
        internalSmallList: [], // 底部小图
        internalMain: "",
        internalMainTitle: "", // 标题
        internalMainList: [], // 右边小图
        internalShowImg: "",
        internalNameList: [], // 柜体数组
      },
      internalAttrCode: "", // 内部AttrCode
      // 内部表单
      interForm: {
        attrCode: "",
        fileList: [],
        parentCode: "",
        internalName: "", // 柜体名称
        internalNameList: [], // 柜体数组
      },

      // 内部编辑图片
      interImgform: {
        itemFileUrl: "",
        id: "",
        itemName: "",
      },
      // 柜门
      cabDoorForm: {
        attrCode: "",
        fileList: [],
      },
      cabDoorAttrCode: "", // 柜门AttrCode
      // 柜门显示
      cabDoorFaceForm: {
        cabImgList: [],
        cabDoorMain: "",
        cabDoorMainTitle: "",
        cabSmallImgList: [],
        cabDoorShowImg: "",
      },
      // 柜门编辑图片
      cabDoorImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },

      // 新增页面表单
      addClassForm: {
        fileList: [],
        attrCode: "",
      },

      // 新增页面显示
      addClassFaceForm: {
        addClassMain: "", // 主图片
        addClassMainTitle: "", // 主标题
        addClassRightImgList: [], // 右侧图片
        addClassBtmImgList: [], // 底部图片
        addClassShowImg: "", // 矩形小图
      },
      addClassAttrCode: "",
      addClassAttrType: "",
      // 新增页面编辑图片
      addClassImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },

      // 添加分类
      increaseForm: {
        increaseName: "",
      },

      addClassDialog: false, // 新增分类图片
      increaseDialog: false, // 添加分类
      dialogFullScreen: false, // 是否为全屏
      showDialog: false,
      videoDialog: false, // 短视频
      vrDialog: false, // vr
      houseDialog: false, // 全屋空间
      houseIsPreview: false, // 全屋空间预览
      renderDialog: false, // 效果图
      renderIsPreview: false, // 效果图预览
      cabinetFileDialog: false, // 柜体右侧
      cabinetShowDialog: false, // 柜体左侧
      canbinetImgDialog: false, // 柜体右边编辑
      canbinDeleImg: false, // 柜体右边删除按钮
      internalDialog: false, // 内部
      interImgDialog: false, // 内部右边编辑
      deleImage: false, // 内部删除按钮
      cabDoorDialog: false, // 柜门
      cabdoorImgDialog: false, // 柜门右边编辑
      cabdoorDeleImg: false, // 柜门右边删除按钮
      isPreview: false, // 柜体图片预览
      addClassImgDialog: false, // 新增图片编辑
      addClassDeleImg: false, // 新增删除按钮
      // 预览的图片
      previewItem: "",
      // 文件上传数量限制
      fileLimit: 30,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      tabPosition: "left",
      // 全屋空间预览图片
      housePreviewItem: "",
      // 效果图预览的图片
      renderPreviewItem: "",
      // 文件类型
      fileType: "",
      // 文件上传数量限制
      // fileLimit: 30,
      editableTabsValue: "1",
      editableTabs: [],
      tabIndex: 7,
      timer: "",
      inputFlag: false,
      itemFileUrl: [],
      activeName: "1", // 默认选择
      houseList: [], // 全屋空间
      houseIndex: "1",
      renderList: [], // 效果图
      renderIndex: "1",
      houseName: "1", // 全屋空间tabs默认选中
      renderName: "1", // 效果图tabs默认选中
      houseImageList: [], // 全屋空间图片展示
      renderImageList: [], // 效果图展示
      renderOptions: [],
      value: "",
      productList: [], // 产品分类名称

      videoCheckAll: false, // 短视频全选
      houseCheckAll: false, // 全屋空间全选
      renderCheckAll: false, // 效果图全选
      videoIndeterminate: true,

      //画矩形相关参数
      rectangles: [], // 保存矩形坐标的数组
      canvasContext: null,
      isDrawing: false,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      drawingMode: false,
      showFloatingImage: false,
      floatingImageLoaded: false,
      showButtonFlag: true,
      floatingImageList: [],
      videoIdList: [],
      videoObject: {},
      houseObject: {},
      houseIdList: [],
      renderObject: {},
      renderIdList: [],
      interObject: {
        idList: [],
      },
      canbinetObject: {
        idList: [],
      },
      cabdoorObject: {
        idList: [],
      },
      addclassObject: {
        idList: [],
      },
      effectList: [],
      effectName: "", //空间图父类(效果图)

      currentHeightRat: "", //屏幕高度缩放比
      currentWidthRat: "", //屏幕宽度缩放比
      productId: "",
    };
  },

  created() {
    this.getList();
    console.log(
      "屏幕",
      window.innerHeight,
      window.innerWidth,
      window.height,
      window.width
    );
    this.currentHeightRat = parseFloat(window.innerHeight / 969).toFixed(2);
    this.currentWidthRat = parseFloat(window.innerWidth / 1920).toFixed(2);
    console.log("currentHeightRat", this.currentHeightRat);
    console.log("currentWidthRat", this.currentWidthRat);
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    //图层管理
    layerManage(code) {
      this.layerDialog = true;
      const data = {};
      data.productCode = code;
      this.$http
        .post("/productExtLayer/queryProductLayer", data)
        .then((res) => {
          if (res.data.code == 200) {
            this.layerDetail = res.data.data;
          }
        });
    },
    //图层排序保存
    saveLayer(row) {
      const data = {};
      data.id = row.id;
      data.seq = row.seq;
      this.$http.post("/productExtLayer/update", data).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true,
          });
          this.layerManage(row.productCode);
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.message,
            showClose: true,
          });
        }
      });
    },
    //上传超出限制
    uploadOverLimit() {
      this.$notify.error({
        title: "提示",
        message: "一次最大上传数不能超过30张！",
        showClose: true,
      });
    },
    moneyChange(e) {
      console.log("e", e);
      console.log("money", this.money);
    },

    // 短视频批量删除全选
    videoCheckAllChange(val) {
      // console.log(val);
      this.coverVideoList.forEach((item) => {
        item.checked = val;
      });
    },

    // 短视频批量删除
    videoBatchDelete() {
      var that = this;
      that.videoIdList = [];
      that.coverVideoList.forEach((item) => {
        if (item.checked) {
          that.videoIdList.push(item.id);
        }
      });
      that.videoObject.idList = that.videoIdList;
      // console.log(that.videoIdList);
      that.$http
        .post("/productExtAttrItem/deleteBatch", that.videoObject)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.clickTab("0");
            that.videoCheckAll = false;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 全屋空间全选
    houseCheckAllChange(val) {
      // console.log(val);
      this.houseImageList.forEach((item) => {
        item.checked = val;
      });
    },

    // 全屋空间批量删除
    houseBatchDelete() {
      var that = this;
      that.houseIdList = [];
      that.houseImageList.forEach((item) => {
        if (item.checked) {
          that.houseIdList.push(item.id);
        }
      });
      that.houseObject.idList = that.houseIdList;
      that.$http
        .post("/productExtAttrItem/deleteBatch", that.houseObject)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            // console.log(that.houseIndex);
            // that.houseTabs(that.houseIndex)
            // console.log(that.houseIndex);
            that.houseCheckAll = false;
            that.allHouseChange(that.effectName);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 效果图全选
    renderCheckAllChange(val) {
      this.renderImageList.forEach((item) => {
        item.checked = val;
      });
    },

    // 效果图批量删除
    renderBatchDelete() {
      var that = this;
      that.renderIdList = [];
      that.renderImageList.forEach((item) => {
        if (item.checked == true) {
          that.renderIdList.push(item.id);
        }
      });
      that.renderObject.idList = that.renderIdList;
      that.$http
        .post("/productExtAttrItem/deleteBatch", that.renderObject)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.renderTabs(that.renderIndex);
            that.renderCheckAll = false;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 弹框关闭清空
    bulletBoxClose() {
      let totalTemp = this.total;
      let queryInfoTemp = this.queryInfo;
      let tableDataTemp = this.tableData;
      Object.assign(this.$data, this.$options.data());
      this.total = totalTemp;
      this.queryInfo = queryInfoTemp;
      this.tableData = tableDataTemp;
    },

    // // 添加分类清空
    incrCloseDialog() {
      this.increaseForm.increaseName = "";
    },

    // // 短视频清空
    videoCloseDialog() {
      //  this.videoForm = {};
      this.videoForm.itemName = "";
      this.videoForm.seq = "";
      this.videoForm.videoCategoryCode = "";
      this.videoForm.itemFileUrl = "";
      this.videoForm.isPortraitScreen = "";
      this.videoForm.showImage = "";
      this.videoForm.title = "";
    },

    // // vr清空
    vrCloseDialog() {
      this.vrForm.itemFileUrl = "";
      this.vrForm.showImage = "";
      this.vrForm.attrCode = "";
    },

    // // 全屋空间清空
    houseCloseDialog() {
      this.houseForm.parentCode = "";
      this.houseForm.itemType = "";
      this.houseForm.fileList = [];
    },

    // // 效果图清空
    renderCloseDialog() {
      this.renderForm.itemType = "";
      this.renderForm.fileList = [];
    },

    // // 柜体1清空
    cabinetFileCloseDialog() {
      this.cabinetForm.fileList = [];
    },

    // // 柜体2清空
    cabinetShowCloseDialog() {
      this.cabinetShowForm.fileList = [];
    },

    // // 内部清空
    internalCloseDialog() {
      this.interForm.fileList = [];
    },

    // // 柜门清空
    cabDoorCloseDialog() {
      this.cabDoorForm.fileList = [];
    },

    // // 添加分类图片清空
    addClassCloseDialog() {
      this.addClassForm.fileList = [];
    },

    // 添加分类图片表单上传
    addClassBtn() {
      this.addClassDialog = true;
    },

    // 添加分类图片取消
    addClassClose() {
      this.addClassDialog = false;
      this.addClassCloseDialog();
    },

    // 添加分类图片确定
    addClassSubmit() {
      var that = this;
      that.addClassDialog = false;
      var formData = that.addClassForm;
      formData.attrCode = that.addClassAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.addClassClose();
            that.clickTab("");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除添加分类图片
    removeAddClassImg(res, file, fileList) {
      // console.log(res);
      const index = this.addClassForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.addClassForm.fileList.splice(index, 1);
    },

    // 添加分类图片上传成功
    uploadAddClassImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.addClassForm.fileList.push(res.data);
    },

    // 添加分类名称打开
    increaseClass() {
      this.increaseDialog = true;
    },
    //点击修改分类按钮
    editTabs() {
      this.isEditTabs = true;
    },
    //点击确定修改按钮
    editTabsComplete() {
      const data = {};
      this.editableTabs.forEach((e) => {
        if (e.attrName == "") {
          this.$notify.error({
            title: "提示",
            message: "分类名称不能为空",
            showClose: true,
          });
          return;
        }
      });
      data.updateAttrList = this.editableTabs;
      data.delAttrCodeList = this.delIdList;
      data.productCode = this.form.productCode;
      this.$http.post("/productExtAttr/updateBatch", data).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true,
          });
          this.isEditTabs = false;
        }
      });
    },
    inputChange(event) {
      console.log(event);
      // 阻止事件冒泡
      // event.stopPropagation();
    },
    //删除分类回调
    removeTab(e) {
      console.log(e);
      const event = window.event;
      // 检查事件源是否为输入框
      if (event.type.toLowerCase() === "keydown") {
        return;
      }
      if (e <= 7) {
        this.$notify.error({
          title: "提示",
          message: "默认分类，不可删除",
          showClose: true,
        });
        return;
      }
      let list = JSON.parse(JSON.stringify(this.editableTabs));

      list.forEach((item, index) => {
        if (item.attrType == e) {
          list.splice(index, 1);
          this.delIdList.push(item.attrCode);
        }
      });
      this.editableTabs = list;
    },
    // 添加分类名称表单关闭
    increaseClose() {
      this.increaseDialog = false;
      this.increaseForm = {};
    },

    // 添加分类名称表单确定
    increaseSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttr/save", {
          productCode: that.form.productCode,
          attrName: that.increaseForm.increaseName,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "添加成功",
              showClose: true,
            });
            that.increaseDialog = false;
            that.edit(that.form.productCode, that.productId);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 获取短视频分类
    getVideocategory() {
      var that = this;
      that.$http.post("/videoCategory/queryList", {}).then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          that.videoOptions = response.data.data;
          that.videoOptions.forEach((item) => {
            // console.log(item);
            item.videoCategoryCode = item.categoryCode;
            item.videoCategoryName = item.categoryName;
          });
          // console.log(that.videoOptions,'111');
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    // 选择上级名称
    chooseName(val) {
      // console.log('val',val);
      this.videoForm.videoCategoryCode = val;
      // console.log(this.videoForm.videoCategoryCode);

      this.$nextTick(() => {
        // console.log(this.$refs.category.selectedLabel);
        this.videoForm.videoCategoryName = this.$refs.category.selectedLabel;
      });
      // this.form.videoCategoryName = this.$refs.category
      // console.log(this.$refs.category)
    },
    // 添加分类
    // increaseClass() {
    //   this.increaseDialog = true
    // },

    // 添加分类表单关闭
    // increaseClose() {
    //   this.increaseDialog = false
    //   this.increaseForm = {}
    // },

    // 添加分类表单确定
    // increaseSubmit() {
    //   var that = this
    //   that.$http.post('/productExtAttr/save',{productCode:that.increaseForm.productCode,attrName:that.increaseForm.increaseName}).then(function(response) {
    //     if (response.data.code == 200) {
    //         that.$notify.success({
    //           title: '提示',
    //           message: '保存成功',
    //           showClose: true
    //         })
    //         that.increaseDialog = false
    //         that.edit(that.increaseForm.productCode)
    //       } else {
    //         that.$notify.info({
    //           title: '提示',
    //           message: response.data.message,
    //           showClose: true
    //         })
    //       }
    //   })
    // },
    // 获取产品分类
    getProduct() {
      var that = this;
      that.$http
        .post("/productCategory/queryTreeList", that.form)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.productList = that.getTreeList(response.data.data);
            // console.log(that.productList);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    getTreeList(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined;
        } else {
          this.getTreeList(data[i].children);
        }
      }
      return data;
    },

    // 产品分类名称选择
    changeProduct(val) {
      // console.log(val);
      if (val && val.length) {
        this.form.categoryCode = val[val.length - 1];
      } else {
        return;
      }

      // console.log(this.categoryCode);
    },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList() {
      var that = this;
      that.queryInfo.condition.productType = "1";
      that.$http
        .post("/product/list", that.queryInfo)
        .then(function (response) {
          // console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      let data = {};
      data = that.form;
      data.productType = "1";
      // if (!this.form.coverImage) {
      //   return;
      // }
      if (this.labelType == "add") {
        that.$http
          .post("/product/save", data)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "保存成功",
                showClose: true,
              });
              that.showDialog = false;
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          })
          .catch(function (error) {});
      } else {
        this.$http
          .post("/product/update", {
            id: this.productId,
            prcture: this.form.prcture,
            doorPrcture: this.addClassFaceForm.addClassMain,
          })
          .then((res) => {});
      }
    },

    // 关闭表单
    homePageClose() {
      this.showDialog = false;
    },

    //修改
    edit(row_productCode, id) {
      console.log(id, "选择产品");
      // this.$router.push('/ProductDetails')
      var that = this;
      that.showDialog = true;
      that.labelType = "edit";
      this.productId = id;
      // this.currentId = id;
      that.getProduct();

      that.$http
        .post("/product/queryProductDetail", { productCode: row_productCode })
        .then(function (response) {
          console.log(response, "=====");
          if (response.data.code == 200) {
            that.form = response.data.data;
            that.houseList = response.data.data.dictList;
            that.renderList = response.data.data.dictList;
            // that.renderOptions = response.data.data.dictList
            that.form.categoryCode = response.data.data.categoryCode;
            // console.log(that.form);
            that.internalForm.productCode = that.form.productCode;
            that.increaseForm.productCode = response.data.data.productCode;
            that.editableTabs = response.data.data.productAttrList;
            if (response.data.data.doorPrcture) {
              that.addClassFaceForm.addClassMain =
                response.data.data.doorPrcture;
              // console.log(that.addClassFaceForm.addClassMain, "=====");
            }
            response.data.data.productAttrList.forEach((item) => {
              if (item.attrType === "1") {
                that.videoForm.attrCode = item.attrCode;
                that.videoAttrCode = item.attrCode;
              } else if (item.attrType === "2") {
                that.vrForm.attrCode = item.attrCode;
                that.vrAttrCode = item.attrCode;
              } else if (item.attrType === "4") {
                that.houseForm.attrCode = item.attrCode;
                that.houseAttrCode = item.attrCode;
              } else if (item.attrType === "3") {
                that.renderForm.attrCode = item.attrCode;
                that.renderAttrCode = item.attrCode;
              } else if (item.attrType === "5") {
                that.cabinetForm.attrCode = item.attrCode;
                that.cabinetShowForm.attrCode = item.attrCode;
                that.cabinetAttrCode = item.attrCode;
                that.cabinetFace.attrCode = item.attrCode;
              } else if (item.attrType === "6") {
                that.interForm.attrCode = item.attrCode;
                that.internalAttrCode = item.attrCode;
              } else if (item.attrType === "7") {
                that.cabDoorForm.attrCode = item.attrCode;
                that.cabDoorAttrCode = item.attrCode;
              }
            });
            that.clickTab("0");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/product/delete", { productCode: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
      this.getProduct();
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 图片上传
    handleSuccess(val) {
      console.log(val, "图片上传");
      this.form.coverImage = val.data.url;
      this.timer = new Date().getTime();
    },

    labelChange() {
      this.inputFlag = true;
    },

    labelBlur() {
      this.inputFlag = false;
    },

    // 点击新增或关闭触发
    // @edit="handleTabsEdit"
    // handleTabsEdit(targetName, action) {
    //     if (action === 'add') {
    //       this.tabIndex = this.editableTabs.length
    //       let newTabName = ++this.tabIndex + '';
    //       this.editableTabs.push({
    //         title: 'New Tab',
    //         name: newTabName,
    //       });
    //       this.editableTabsValue = newTabName;
    //     }
    //     if (action === 'remove') {
    //       let tabs = this.editableTabs;
    //       let activeName = this.editableTabsValue;
    //       if (activeName === targetName) {
    //         tabs.forEach((tab, index) => {
    //           if (tab.name === targetName) {
    //             let nextTab = tabs[index + 1] || tabs[index - 1];
    //             if (nextTab) {
    //               activeName = nextTab.name;
    //             }
    //           }
    //         });
    //       }

    //       this.editableTabsValue = activeName;
    //       this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    //     }
    //   },

    // // 点击时tabs触发
    clickTab(val) {
      console.log("clickTab111111111111111111111", val);
      // debugger
      var moduleName = "";
      var that = this;
      let index = val.index;
      if (val == "0") {
        index = "0";
      } else if (val == "1") {
        index = "1";
      } else if (val == "2") {
        index = "2";
      } else if (val == "3") {
        index = "3";
      } else if (val == "4") {
        index = "4";
      } else if (val == "5") {
        index = "5";
      } else if (val == "6") {
        index = "6";
      }

      // 短视频
      if (index == "0") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        that.$http
          .post("/productExtAttrItem/queryList", {
            attrCode: that.videoAttrCode,
          })
          .then(function (response) {
            console.log(response);
            that.coverVideoList = [];
            if (response.data.code == 200) {
              response.data.data.forEach((item) => {
                let obj = {};
                obj.itemFileUrl = item.itemFileUrl;
                obj.id = item.id;
                obj.checked = false;
                that.coverVideoList.push(obj);
              });
              // console.log(that.coverVideoList);
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      } else if (index == "1") {
        // vr
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        that.$http
          .post("/productExtAttrItem/queryList", { attrCode: that.vrAttrCode })
          .then(function (response) {
            // console.log(response);
            if (response.data.code == 200) {
              response.data.data.forEach((item) => {
                that.vrShowForm.showImage = item.showImage;
                that.vrShowForm.itemFileUrl = item.itemFileUrl;
                that.vrForm.id = item.id;
              });
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      } else if (index == "3") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        // 全屋空间
        that.houseIndex = "1";
        that.houseName = "1";
        // that.houseTabs(that.houseIndex)
        that.getEffectType();
      } else if (index == "2") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        // 效果图
        that.renderName = "1";
        that.renderIndex = "1";
        that.renderTabs(that.renderIndex);
      } else if (index == "4") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        that.canbinetImgDialog = false;
        that.canbinDeleImg = false;
        // 柜体（色卡）
        that.$http
          .post("/productExtAttrItem/queryList", {
            attrCode: that.cabinetAttrCode,
          })
          .then(function (response) {
            // console.log(response);
            if (response.data.code == 200) {
              that.cabinetFace.cabinetImageList = [];
              that.cabinetFace.cabinetImageList = response.data.data;
              response.data.data.forEach((item, index) => {
                if (index == "0") {
                  that.cabinetFace.cabinetMainImage = item.itemFileUrl;
                  that.cabinetFace.cabinetMainTitle = item.itemName;
                }
              });
              that.cabinetFace.colorImageList = response.data.data;
              // console.log(that.cabinetFace.colorImageList);
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      } else if (index == "5") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        // 内部结构下拉框
        that.deleImage = false;
        that.interImgDialog = false;
        that.getInterSelectList();
        moduleName = "internal_";
      } else if (index == "6") {
        var attrObj = that.editableTabs[index];
        that.addClassAttrType = attrObj.attrType;
        // 柜门
        that.cabdoorDeleImg = false;
        that.cabdoorImgDialog = false;
        that.getCabDoorSelectList(index);
        that.$http
          .post("/productExtAttrItem/queryList", {
            attrCode: that.cabDoorAttrCode,
          })
          .then(function (response) {
            // console.log(response);
            if (response.data.code == 200) {
              that.cabDoorFaceForm.cabImgList = [];
              that.cabDoorFaceForm.cabSmallImgList = [];
              response.data.data.forEach((item, index) => {
                let obj = {};
                obj.id = item.id;
                obj.itemFileUrl = item.itemFileUrl;
                obj.itemName = item.itemName;
                that.cabDoorFaceForm.cabImgList.push(obj);
                that.cabDoorFaceForm.cabSmallImgList.push(obj);
                if (index == "0") {
                  that.cabDoorFaceForm.cabDoorShowImg = item.itemFileUrl;
                  that.cabDoorFaceForm.cabDoorMainTitle = item.itemName;
                }
              });
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
        moduleName = "cabDoor_";
      } else {
        that.addClassDeleImg = false;
        that.addClassImgDialog = false;
        let attrCode = "";
        if (!index && that.addClassAttrCode) {
          attrCode = that.addClassAttrCode;
        } else {
          var attrObj = that.editableTabs[index];
          // console.log(attrObj);
          if (attrObj) {
            attrCode = attrObj.attrCode;
            that.addClassAttrCode = attrCode;
            that.addClassAttrType = attrObj.attrType;
          }
        }

        that.getCabDoorSelectList(index);
        // console.log('attrCode',attrCode);
        // 新增的分类
        that.$http
          .post("/productExtAttrItem/queryList", { attrCode: attrCode })
          .then(function (response) {
            // console.log(response);
            if (response.data.code == 200) {
              // console.log('新增的分类',response.data.data);
              that.addClassFaceForm.addClassRightImgList = [];
              that.addClassFaceForm.addClassBtmImgList = [];
              that.addClassFaceForm.addClassMainTitle = "";
              response.data.data.forEach((item, index) => {
                let obj = {};
                obj.id = item.id;
                obj.itemFileUrl = item.itemFileUrl;
                obj.itemName = item.itemName;
                that.addClassFaceForm.addClassRightImgList.push(obj);
                that.addClassFaceForm.addClassBtmImgList.push(obj);
                if (index == "0") {
                  that.addClassFaceForm.addClassMainTitle = item.itemName;
                  that.addClassFaceForm.addClassShowImg = item.itemFileUrl;
                }
              });
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
        moduleName = "addClass_";
      }

      //取消绘制，并清空矩形回显数据
      that.cancelDrawing();

      that.$nextTick(() => {
        if (moduleName) {
          that.onImageLoad(moduleName);
        }
      });
    },

    // 柜体下拉获取图片信息
    getCabDoorSelectList(attrListIndex) {
      var that = this;
      that.$http
        .post("/productExtAttrItem/queryList", {
          productCode: that.internalForm.productCode,
          attrType: "3",
        })
        .then(function (response) {
          // console.log('xiala',response);
          if (response.data.code == 200) {
            // that.cabDoorFaceForm.cabSmallImgList = []
            // that.cabDoorFaceForm.cabSmallImgList = response.data.data
            response.data.data.forEach((item, index) => {
              if (index == "0") {
                if (attrListIndex == "6") {
                  that.cabDoorFaceForm.cabDoorMain = item.itemFileUrl;
                } else {
                  // that.addClassFaceForm.addClassMain = item.itemFileUrl;
                }

                // that.cabDoorFaceForm.cabDoorMainTitle = item.itemName
                return;
              }
            });

            // that.getInterList()
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 内部结构下拉框
    getInterSelectList() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/queryList", {
          productCode: that.internalForm.productCode,
          attrType: "5",
        })
        .then(function (response) {
          // console.log('xiala',response);
          if (response.data.code == 200) {
            that.interForm.internalNameList = response.data.data;
            that.internalForm.internalNameList = response.data.data;
            if (that.interForm.internalNameList.length > 0) {
              that.interForm.internalName =
                that.interForm.internalNameList[0].itemName;
              that.interForm.parentCode =
                that.interForm.internalNameList[0].itemCode;
            }
            if (that.internalForm.internalNameList.length > 0) {
              that.internalForm.internalName =
                that.internalForm.internalNameList[0].itemName;
              // that.internalForm.internalMainTitle  = that.internalForm.internalNameList[0].itemName
              that.internalForm.internalMain =
                that.internalForm.internalNameList[0].itemFileUrl;
              that.internalForm.parentCode =
                that.internalForm.internalNameList[0].itemCode;
            }

            that.getInterList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    //全屋空间下拉框
    getEffectType() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/queryList", {
          productCode: that.internalForm.productCode,
          attrType: "3",
        })
        .then(function (response) {
          // console.log('xiala',response);
          if (response.data.code == 200) {
            that.effectList = response.data.data;
            that.effectName = response.data.data[0].itemCode;
            that.allHouseChange(response.data.data[0].itemCode);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 内部结构表单显示
    getInterList() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/queryList", {
          attrCode: that.internalAttrCode,
          parentCode: that.interForm.parentCode,
        })
        .then(function (response) {
          // console.log('c',response);
          if (response.data.code == 200) {
            that.internalForm.internalMainList = [];
            that.internalForm.internalSmallList = [];
            response.data.data.forEach((item, index) => {
              let obj = {};
              obj.itemFileUrl = item.itemFileUrl;
              obj.id = item.id;
              obj.itemName = item.itemName;
              that.internalForm.internalMainList.push(obj);
              that.internalForm.internalSmallList.push(obj);

              // that.internalForm.internalMainTitle.push(item.itemFileUrl)
              if (index == "0") {
                that.internalForm.internalShowImg = item.itemFileUrl;
                that.internalForm.internalMainTitle = item.itemName;
              }
            });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 短视频上传表单
    videoBtn() {
      this.videoDialog = true;
      // this.getVideocategory()
    },

    // 移除视频
    removeVideo(val) {
      // console.log(val);
    },

    // 全屋空间上传表单
    houseBtn() {
      this.houseDialog = true;
    },

    // 效果图上传表单
    renderBtn() {
      this.renderDialog = true;
    },

    // 短视频上传
    videoSuccess(val) {
      // console.log(val);
      this.videoForm.itemFileUrl = val.data.url;
    },

    // 短视频上传改变
    videohandleChange(val) {
      var that = this;
      console.log(val);
      this.$nextTick(() => {
        let url = val.response?.data?.url;
        const newvideoUrl = url;
        const videoObj = document.createElement("video");
        videoObj.preload = "metadata";
        videoObj.src = newvideoUrl;
        videoObj.onloadedmetadata = function (evt) {
          URL.revokeObjectURL(newvideoUrl);
          let width = parseInt(videoObj.videoWidth); //获取视频的宽
          let height = parseInt(videoObj.videoHeight); //获取视频的高
          console.log("width, height:", width, height);
          if (width > height) {
            that.videoForm.isPortraitScreen = "0";
          } else if (width < height) {
            that.videoForm.isPortraitScreen = "1";
          }
          // console.log(that.videoForm.isPortraitScreen);
        };
      });
    },

    // 短视频封面上传
    videoImageSuccess(val) {
      this.videoForm.showImage = val.data.url;
    },

    // 短视频取消按钮
    videoClose() {
      this.videoDialog = false;
      this.videoCloseDialog();
    },

    // 短视频确定按钮
    videoSubmit() {
      var that = this;
      var formData = that.videoForm;
      formData.attrCode = that.videoAttrCode;
      that.$http
        .post("/productExtAttrItem/save", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("0");
            that.videoClose();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // vr上传表单
    vrBtn() {
      this.vrDialog = true;
    },

    // vr封面图片上传
    vrSuccess(res, file) {
      this.vrForm.showImage = res.data.url;
      this.timer = new Date().getTime();
      // console.log(this.form.coverImage);
    },

    // vr取消按钮
    vrClose() {
      this.vrDialog = false;
      this.vrCloseDialog();
    },

    // vr确定按钮
    vrSubmit() {
      var that = this;
      that.vrDialog = false;
      var formData = that.vrForm;
      formData.attrCode = that.vrAttrCode;
      that.$http
        .post("/productExtAttrItem/save", that.vrForm)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("1");
            that.vrCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 全屋空间列表tabs切换
    houseTabs(val) {
      // console.log(val);
      var that = this;
      that.houseCheckAll = false;
      if (val && val.name) {
        that.houseIndex = val.name;
      }
      var valName = that.houseIndex;

      that.$http
        .post("/productExtAttrItem/queryList", {
          attrCode: that.houseAttrCode,
          itemType: valName,
        })
        .then(function (response) {
          // console.log(response);
          that.houseImageList = [];
          if (response.data.code == 200) {
            response.data.data.forEach((item) => {
              let obj = {};
              obj.itemFileUrl = item.itemFileUrl;
              obj.id = item.id;
              obj.checked = false;
              that.houseImageList.push(obj);
            });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    allHouseChange(val) {
      var that = this;
      const data = {};
      data.parentCode = val;
      that.$http
        .post("/productExtAttrItem/queryList", {
          attrCode: that.houseAttrCode,
          parentCode: val,
        })
        .then(function (response) {
          // console.log('下拉',response);
          if (response.data.code == 200) {
            let list = response.data.data;
            list.forEach((e) => {
              e.checked = false;
            });
            that.houseImageList = list;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 全屋空间表单关闭
    houseClose() {
      this.houseDialog = false;
      this.houseCloseDialog();
    },

    // 全屋空间表单确认
    houseSubmit() {
      var that = this;
      that.houseDialog = false;
      var formData = that.houseForm;
      formData.attrCode = that.houseAttrCode;

      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            // that.clickTab('2')
            // console.log(that.houseIndex);
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            // that.houseTabs(that.houseIndex)
            that.houseCloseDialog();
            that.allHouseChange(that.effectName);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 全屋空间下拉选择
    houseSelect(val) {
      // console.log(val);
      this.houseForm.parentCode = val;
    },

    // 全屋空间预览图片
    housePreviewImg(file) {
      // console.log(file);
      this.houseIsPreview = true;
      this.housePreviewItem = file.url;
    },

    // 全屋空间删除图片
    houseRemoveImg(res, file, fileList) {
      // console.log(res);
      const index = this.houseForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.houseForm.fileList.splice(index, 1);
    },

    // 全屋空间图片上传
    houseUploadImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.houseForm.fileList.push(res.data);
    },

    // 效果图tabs切换
    renderTabs(val) {
      var that = this;
      that.renderCheckAll = false;
      if (val && val.name) {
        that.renderIndex = val.name;
      }
      var valName = that.renderIndex;

      that.$http
        .post("/productExtAttrItem/queryList", {
          attrCode: that.renderAttrCode,
        })
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.renderImageList = [];
            response.data.data.forEach((item) => {
              let obj = {};
              obj.itemFileUrl = item.itemFileUrl;
              obj.id = item.id;
              obj.checked = false;
              obj.itemName = item.itemName;
              that.renderImageList.push(obj);
            });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
          //   response.data.data.forEach(item => {
          //   that.vrForm.itemFileUrl = item.itemFileUrl
          // })
        });
    },

    // 效果图表单关闭
    renderClose() {
      this.renderDialog = false;
      this.renderCloseDialog();
    },

    // 效果图表单确认
    renderSubmit() {
      var that = this;
      that.renderDialog = false;
      var formData = that.renderForm;
      formData.attrCode = that.renderAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.renderTabs(that.renderIndex);
            that.renderCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 效果图下拉选择
    renderSelect(val) {
      // console.log(val);
      this.renderForm.itemType = val;
    },

    // 效果图预览图片
    renderPreviewImg(file) {
      // console.log(file);
      this.renderIsPreview = true;
      this.renderPreviewItem = file.url;
    },

    // 效果图删除图片
    renderRemoveImg(res, file, fileList) {
      // console.log(res);
      const index = this.renderForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.renderForm.fileList.splice(index, 1);
    },

    // 效果图上传
    renderUploadImg(res, file, fileList) {
      console.log(res);
      // console.log(fileList);
      this.renderForm.fileList.push(res.data);
    },

    // 柜体右侧上传图片
    cabinetFileBtn() {
      this.cabinetFileDialog = true;
    },

    // 柜体左侧上传图片
    cabinetShowBtn() {
      this.cabinetShowDialog = true;
    },
    // 预览图片
    previewImg(file) {
      // console.log(file);
      this.isPreview = true;
      this.previewItem = file.url;
    },

    // 删除色卡主图图片
    removeImg(res, file, fileList) {
      // console.log(res);
      const index = this.cabinetForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.cabinetForm.fileList.splice(index, 1);
    },

    // 删除色卡展示图
    removeShowImg(res, file, fileList) {
      // console.log(res);
      const index = this.cabinetShowForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.cabinetShowForm.fileList.splice(index, 1);
    },

    // 柜体主图图片上传成功
    uploadImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.cabinetForm.fileList.push(res.data);
    },

    // 柜体展示图图片上传
    uploadShowImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.cabinetShowForm.fileList.push(res.data);
    },

    // 柜体右侧表单关闭
    cabinetClose() {
      this.cabinetFileDialog = false;
      this.cabinetFileCloseDialog();
    },

    // 柜体右侧表单确认
    cabinetSubmit() {
      var that = this;
      that.cabinetFileDialog = false;
      var formData = that.cabinetForm;
      formData.attrCode = that.cabinetAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("4");
            that.cabinetFileCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 柜体左侧表单关闭
    cabinetShowClose() {
      this.cabinetShowDialog = false;
      this.cabinetShowCloseDialog();
    },

    // 柜体左侧表单确认
    cabinetShowSubmit() {
      var that = this;
      that.cabinetShowDialog = false;
      var formData = that.cabinetShowForm;
      formData.attrCode = that.cabinetAttrCode;
      that.$http
        .post("/productExtAttrItem/saveColorBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("4");
            that.cabinetShowCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 柜体保存按钮
    cabinetSave() {
      var that = this;
      var formData = that.cabinetFace;
      formData.attrCode = that.cabinetAttrCode;
      that.$http
        .post("/productExtAttrItem/save", that.cabinetFace)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 柜体右边编辑
    canbinetChangeImg(item) {
      // console.log(item);
      this.canbinetImgDialog = true;
      this.cabinetImgform.itemFileUrl = item.itemFileUrl;
      this.cabinetImgform.id = item.id;
    },

    // 柜体编辑图片上传
    canbinChangeImgSuccess(res, file) {
      // console.log(res);
      this.cabinetImgform.itemFileUrl = res.data.url;
      this.cabinetImgform.itemName = res.data.fileName;
    },

    // 柜体编辑表单关闭
    canbinImgClose() {
      this.canbinetImgDialog = false;
      this.cabinetImgform.itemFileUrl = "";
      this.cabinetImgform.id = "";
    },

    // 柜体编辑表单确认
    canbinImgSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/updateFileUrl", that.cabinetImgform)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "编辑成功",
              showClose: true,
            });
            that.canbinImgClose();
            that.clickTab("4");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 柜体右侧图片删除
    canbinetBtnDele() {
      this.canbinetObject.idList = [];
      this.canbinDeleImg = !this.canbinDeleImg;
      if (!this.canbinDeleImg) {
        this.canbinetImgDialog = false;
      } else {
        this.canbinetImgDialog = true;
      }
    },

    // 柜体右边编辑删除
    async canbinetSureDele(item) {
      // console.log(item);
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.canbinetObject.idList.push(item.id);
        // console.log(that.canbinetObject.idList);
        that.$http
          .post("/productExtAttrItem/deleteBatch", that.canbinetObject)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.canbinDeleImg = false;
              that.canbinetImgDialog = false;
              that.clickTab("4");
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    // 柜体小图点击切换
    switchImg(item, index) {
      // console.log(item);
      // console.log(index);
      this.cabinetFace.cabinetMainImage = item.itemFileUrl;
      this.cabinetFace.cabinetMainTitle = item.itemName;
    },

    // 内部结构上传
    internalBtn() {
      this.internalDialog = true;
      // this.getInterSelectList()
    },

    // 内部右侧图片删除
    internalBtnDele() {
      this.interObject.idList = [];
      this.deleImage = !this.deleImage;
      if (!this.deleImage) {
        this.interImgDialog = false;
      } else {
        this.interImgDialog = true;
      }
    },

    // 内部表单关闭
    internalClose() {
      this.internalDialog = false;
      this.internalCloseDialog();
    },

    // 内部表单确认
    internalSubmit() {
      var that = this;
      that.internalDialog = false;
      var formData = that.interForm;
      formData.attrCode = that.internalAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("5");
            that.internalCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除内部图片
    removeInternalImg(res, file, fileList) {
      // console.log(res);
      const index = this.interForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.interForm.fileList.splice(index, 1);
    },

    // 内部图片上传成功
    uploadInternalImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.interForm.fileList.push(res.data);
    },

    // 内部选中下拉框
    internalSelsec(val) {
      // console.log(val);
      this.interForm.parentCode = val;
    },

    // 内部表面选中下拉框
    interSelsec(val) {
      // console.log(val);
      var that = this;
      that.internalForm.parentCode = val;
      that.$http
        .post("/productExtAttrItem/queryList", {
          attrCode: that.internalAttrCode,
          parentCode: val,
        })
        .then(function (response) {
          // console.log('下拉',response);
          if (response.data.code == 200) {
            that.internalForm.internalMainList = [];
            that.internalForm.internalSmallList = [];
            response.data.data.forEach((item, index) => {
              let obj = {};
              obj.itemFileUrl = item.itemFileUrl;
              obj.id = item.id;
              obj.itemName = item.itemName;
              that.internalForm.internalMainList.push(obj);
              that.internalForm.internalSmallList.push(obj);
              if (index == "0") {
                that.internalForm.internalShowImg = item.itemFileUrl;
              }
            });
            if (that.internalForm.internalSmallList.length > 0) {
              that.internalForm.internalMainTitle =
                that.internalForm.internalSmallList[0].itemName;
            } else {
              that.internalForm.internalMainTitle = "";
            }
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 内部选中小图切换
    switchInternalImg(item) {
      // var that = this
      // this.internalForm.internalMain = item
      this.internalForm.internalShowImg = item.itemFileUrl;
      this.internalForm.internalMainTitle = item.itemName;
      this.floatingImageList.forEach((image, index) => {
        image.url = item.itemFileUrl;
      });
      // that.internalForm.internalMainTitle = item.itemName
      // that.internalForm.internalShowImg = ''
      // that.$http.post('/productExtAttrItem/queryList',{ attrCode: that.internalAttrCode,parentCode: item.itemCode }).then(function(response) {
      //   // console.log(response);
      //   if(response.data.code == 200) {
      //     that.internalForm.internalMainList = []
      //     response.data.data.forEach((item,index) => {
      //       that.internalForm.internalMainList.push(item.itemFileUrl)
      //       if(index == '0') {
      //         that.internalForm.internalShowImg = item.itemFileUrl
      //       }
      //     })
      //   }
      // })
      //取消绘制，并清空矩形回显数据
      // this.cancelDrawing();
    },

    // 内部右边点击编辑
    changeRightImg(item) {
      // console.log(item);
      this.interImgDialog = true;
      // if(this.deleImage) {
      //   this.interImgDialog = false
      // }
      this.interImgform.itemFileUrl = item.itemFileUrl;
      this.interImgform.id = item.id;
    },

    // 内部右边图片上传
    interChangeImgSuccess(res, file) {
      // console.log(res);
      this.interImgform.itemFileUrl = res.data.url;
      this.interImgform.itemName = res.data.fileName;
    },

    // 内部右边编辑关闭
    interImgClose() {
      this.interImgDialog = false;
      this.interImgform.itemFileUrl = "";
      this.interImgform.id = "";
      this.interImgform.itemName = "";
    },

    // 内部右边编辑确认
    interImgSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/updateFileUrl", that.interImgform)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "编辑成功",
              showClose: true,
            });
            that.interImgClose();
            that.clickTab("5");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 内部右边编辑删除
    async sureDele(item) {
      // console.log(item);
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.interObject.idList.push(item.id);
        // console.log(that.interObject.idList);
        that.$http
          .post("/productExtAttrItem/deleteBatch", that.interObject)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.deleImage = false;
              that.interImgDialog = false;
              that.clickTab("5");
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    // 柜门右侧图片删除
    cabdoorBtnDele() {
      this.cabdoorObject.idList = [];
      this.cabdoorDeleImg = !this.cabdoorDeleImg;
      if (!this.cabdoorDeleImg) {
        this.cabdoorImgDialog = false;
      } else {
        this.cabdoorImgDialog = true;
      }
    },

    // 柜门右边编辑删除
    async cabdoorSureDele(item) {
      // console.log(item);
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.cabdoorObject.idList.push(item.id);
        // console.log(that.cabdoorObject.idList);
        that.$http
          .post("/productExtAttrItem/deleteBatch", that.cabdoorObject)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.cabdoorDeleImg = false;
              that.cabdoorImgDialog = false;
              that.clickTab("6");
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    // 柜门右边点击编辑
    cabDoorChangeShow(item) {
      this.cabdoorImgDialog = true;
      this.cabDoorImgform.itemFileUrl = item.itemFileUrl;
      this.cabDoorImgform.id = item.id;
    },

    // 柜门右边图片编辑上传
    cabdoorChangeImgSuccess(res, file) {
      this.cabDoorImgform.itemFileUrl = res.data.url;
      this.cabDoorImgform.itemName = res.data.fileName;
    },

    // 柜门右边编辑表单关闭
    cabdoorImgClose() {
      this.cabdoorImgDialog = false;
      this.cabDoorImgform.itemFileUrl = "";
      this.cabDoorImgform.id = "";
      this.cabDoorImgform.itemName = "";
    },

    // 柜门右边编辑表单确认
    cabdoorImgSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/updateFileUrl", that.cabDoorImgform)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "编辑成功",
              showClose: true,
            });
            that.cabdoorImgClose();
            that.clickTab("6");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 柜门上传表单
    cabDoorBtn() {
      this.cabDoorDialog = true;
    },

    // 柜门取消按钮
    cabdoorClose() {
      this.cabDoorDialog = false;
      this.cabDoorCloseDialog();
    },

    // 柜门确定按钮
    cabdoorSubmit() {
      var that = this;
      that.cabDoorDialog = false;
      var formData = that.cabDoorForm;
      formData.attrCode = that.cabDoorAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab("6");
            that.cabDoorCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除柜门图片
    removeCabdoorImg(res, file, fileList) {
      // console.log(res);
      const index = this.cabDoorForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.cabDoorForm.fileList.splice(index, 1);
    },

    // 柜门图片上传成功
    uploadCabdoorImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.cabDoorForm.fileList.push(res.data);
    },

    // 柜门小图选中
    switchCabImg(item, index) {
      this.cabDoorFaceForm.cabDoorShowImg = item.itemFileUrl;
      this.floatingImageList.forEach((image, index) => {
        image.url = item.itemFileUrl;
      });
      // this.cabDoorFaceForm.cabDoorMain = item.itemFileUrl
      this.cabDoorFaceForm.cabDoorMainTitle = item.itemName;
    },

    // 内部切换小图
    // interchangeShow(item) {
    //   // console.log(item);
    //   this.internalForm.internalShowImg = item
    //   this.floatingImageList.forEach((image,index)=>{
    //     image.url = item
    //   })
    // },

    // 新增右边编辑表单打开
    addclassChangeShow(item) {
      this.addClassImgDialog = true;
      this.addClassImgform.itemFileUrl = item.itemFileUrl;
      this.addClassImgform.id = item.id;
    },

    // 新增右边图片编辑上传
    addclassChangeImgSuccess(res, file) {
      this.addClassImgform.itemFileUrl = res.data.url;
      this.addClassImgform.itemName = res.data.fileName;
    },

    // 新增右边编辑表单关闭
    addImgClose() {
      this.addClassImgDialog = false;
      this.addClassImgform.itemFileUrl = "";
      this.addClassImgform.id = "";
      this.addClassImgform.itemName = "";
    },

    // 新增右边编辑表单确认
    addImgSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/updateFileUrl", that.addClassImgform)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "编辑成功",
              showClose: true,
            });
            that.addImgClose();
            that.clickTab("");
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 新增底部小图选中
    addclassCabImg(item, index) {
      this.addClassFaceForm.addClassShowImg = item.itemFileUrl;
      this.floatingImageList.forEach((image, index) => {
        image.url = item.itemFileUrl;
      });
      // this.cabDoorFaceForm.cabDoorMain = item.itemFileUrl
      this.addClassFaceForm.addClassMainTitle = item.itemName;
    },

    // 新增右边图片删除
    addclassBtnDele() {
      this.addclassObject.idList = [];
      this.addClassDeleImg = !this.addClassDeleImg;
      if (!this.addClassDeleImg) {
        this.addClassImgDialog = false;
      } else {
        this.addClassImgDialog = true;
      }
    },

    // 新增右边删除
    async addclassSureDele(item) {
      // console.log(item);
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.addclassObject.idList.push(item.id);
        that.$http
          .post("/productExtAttrItem/deleteBatch", that.addclassObject)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.addClassDeleImg = false;
              that.addClassImgDialog = false;
              that.clickTab("");
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    //画矩形
    onImageLoad(moduleName) {
      console.log("onImageLoad222222222222222");
      let index = 0;
      if (this.addClassAttrType && this.addClassAttrType - 8 > 0) {
        index = this.addClassAttrType - 8;
      }
      // 在底部图片加载完成后，设置画布尺寸与底部图片相同，并将其定位在底部图片之上
      if (
        this.$refs[moduleName + "image"] &&
        this.$refs[moduleName + "canvas"]
      ) {
        const image = this.$refs[moduleName + "image"][index];
        const canvas = this.$refs[moduleName + "canvas"][index];
        if (image && canvas) {
          canvas.width = image.clientWidth;
          canvas.height = image.clientHeight;
          console.log("canvas.width", canvas.width);
          console.log("canvas.height", canvas.height);
          canvas.style.zIndex = 30;
          this.canvasContext = canvas.getContext("2d");
        }
      }

      // 在底部图片加载完成后，绘制保存的矩形坐标
      // this.drawAllRectangles();

      // 根据已保存的矩形坐标显示浮动图片
      // this.updateFloatingImage();
    },
    //上传门板图
    handleCabDoorMainSuccess(val) {
      //form.prcture
      this.$set(this.form, "prcture", val.data.url);
    },
    handleCabDoorMainSuccess1(val) {
      //form.prcture
      this.$set(this.addClassFaceForm, "addClassMain", val.data.url);
      // that.addClassFaceForm.addClassMain = item.itemFileUrl;
    },
    beforeCabDoorMainUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      console.log("+++++++++++++++++++++++++++++++++++++", isLt2M, file);

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },

    // 开始绘制模式
    startDrawingMode() {
      this.drawingMode = true;
      this.rectangles = [];
      this.clearCanvas();
      this.hideFloatingImage();
    },

    // 开始绘制矩形
    startDrawing(event) {
      if (!this.drawingMode) return;
      this.isDrawing = true;
      const rect = event.target.getBoundingClientRect();
      console.log("event.clientX", event.clientX);
      console.log(
        "this.canvasContext.canvas.width",
        this.canvasContext.canvas.width
      );
      console.log("this.currentWidthRat", this.currentWidthRat);
      console.log("rect.left", rect.left);
      //画的位置在距离你的canvas左边框的距离
      this.startX =
        ((event.clientX - rect.left) / this.canvasContext.canvas.width) * 100;
      //画的位置在距离你的canvas上边框的距离
      this.startY =
        ((event.clientY - rect.top) / this.canvasContext.canvas.height) * 100;
    },

    // 绘制矩形
    drawRectangle(event) {
      if (!this.drawingMode || !this.isDrawing) return;
      const rect = event.target.getBoundingClientRect();
      //画的位置在距离你的canvas左边框的距离
      this.currentX =
        ((event.clientX - rect.left) / this.canvasContext.canvas.width) * 100;
      //画的位置在距离你的canvas上边框的距离
      this.currentY =
        ((event.clientY - rect.top) / this.canvasContext.canvas.height) * 100;
      this.clearCanvas();
      this.drawAllRectangles();
      const width = Math.abs(this.currentX - this.startX);
      const height = Math.abs(this.currentY - this.startY);
      if (width > 0 && height > 0) {
        this.drawSingleRectangle(
          Math.min(this.startX, this.currentX),
          Math.min(this.startY, this.currentY),
          width,
          height
        );
      }
    },

    // 停止绘制矩形
    stopDrawing() {
      if (!this.drawingMode || !this.isDrawing) return;
      this.isDrawing = false;
      const width = Math.abs(this.currentX - this.startX);
      const height = Math.abs(this.currentY - this.startY);
      if (width > 0 && height > 0) {
        this.rectangles.push({
          x: Math.min(this.startX, this.currentX),
          y: Math.min(this.startY, this.currentY),
          width,
          height,
        });
        // this.showFloatingImage = true;
        // this.updateFloatingImage();
        this.rectangleForm = this.rectangles[0];
        this.isShowflags = true;
      }
      this.clearCanvas();
      this.drawAllRectangles();
    },

    // 绘制单个矩形
    drawSingleRectangle(x, y, width, height) {
      const canvasWidth = this.canvasContext.canvas.width;
      const canvasHeight = this.canvasContext.canvas.height;
      this.canvasContext.strokeStyle = "red";
      this.canvasContext.lineWidth = 2;
      this.canvasContext.setLineDash([4, 4]);
      // this.canvasContext.strokeRect(x, y, width, height);
      this.canvasContext.strokeRect(
        (x * canvasWidth) / 100,
        (y * canvasHeight) / 100,
        (width * canvasWidth) / 100,
        (height * canvasHeight) / 100
      );
    },

    // 绘制所有矩形
    drawAllRectangles() {
      this.rectangles.forEach((rectangle) => {
        this.drawSingleRectangle(
          rectangle.x,
          rectangle.y,
          rectangle.width,
          rectangle.height
        );
      });
    },

    // 清除画布
    clearCanvas() {
      if (this.canvasContext) {
        this.canvasContext.clearRect(
          0,
          0,
          this.canvasContext.canvas.width,
          this.canvasContext.canvas.height
        );
      }
    },

    // 隐藏浮动图片
    hideFloatingImage() {
      this.showFloatingImage = false;
      this.showButtonFlag = true;
    },

    // 将矩形坐标保存到数据库
    saveRectangles(val) {
      // console.log(this.rectangles)
      this.rectangles[0] = this.rectangleForm;

      let address = JSON.stringify(this.rectangles);
      // console.log(address)

      var that = this;
      that.$http
        .post("/productExtLayer/save", {
          attrCode: val,
          locationAddress: address,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            // 清空操作
            that.cancelDrawing();
            that.$notify.success({
              title: "提示",
              message: "绘制成功",
              showClose: true,
            });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 取消绘制，并清空矩形回显数据
    cancelDrawing() {
      this.drawingMode = false;
      this.isDrawing = false;
      this.isShowflags = false;

      this.rectangleForm = {};
      this.rectangles = [];
      this.clearCanvas();
      this.hideFloatingImage();
      this.floatingImageList = [];
    },

    //展示矩形中图片
    showDrawing(val, moduleName) {
      // console.log(val);
      // 从数据库中获取矩形坐标
      var that = this;
      that.floatingImageLoaded = true;
      that.showFloatingImage = true;
      var floatingImage = "";
      if (moduleName == "internal_") {
        floatingImage = that.internalForm.internalShowImg;
      } else if (moduleName == "cabDoor_") {
        floatingImage = that.cabDoorFaceForm.cabDoorShowImg;
      } else if (moduleName == "addClass_") {
        floatingImage = that.addClassFaceForm.addClassShowImg;
      }

      that.$http
        .post("/productExtLayer/queryOne", {
          attrCode: val,
          floatingImage: floatingImage,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            if (response.data.data) {
              //浮动图片集合
              that.floatingImageList = response.data.data.floatingImageList;
              that.rectangles = JSON.parse(response.data.data.locationAddress);
              that.updateFloatingImage(moduleName);
            }
          } else {
            that.floatingImageLoaded = false;
            that.showFloatingImage = false;
            //展示 显示矩形 按钮
            that.showButtonFlag = true;
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 更新浮动图片的位置和大小，使其仅显示在底部图片所绘制的矩形中
    updateFloatingImage(moduleName) {
      this.$nextTick(() => {
        let imageArrIndex = 0;
        if (this.addClassAttrType && this.addClassAttrType - 8 > 0) {
          imageArrIndex = this.addClassAttrType - 8;
        }
        const image = this.$refs[moduleName + "image"][imageArrIndex];
        const floatingImageArr = this.floatingImageList;
        //若图片不存在，或浮动图片少于返回的坐标集合时，不显示浮动图片
        if (
          !image ||
          floatingImageArr.length < 1 ||
          this.rectangles.length > floatingImageArr.length
        )
          return;
        if (this.rectangles.length > 0 && this.floatingImageLoaded) {
          for (let index = 0; index < this.rectangles.length; index++) {
            const rect = this.rectangles[index];
            // const scaleX = image.clientWidth / image.naturalWidth;
            // const scaleY = image.clientHeight / image.naturalHeight;
            const floatingImageDiv =
              this.$refs[`${moduleName}floatingImageList`][imageArrIndex];
            const floatingImage = Array.from(
              floatingImageDiv.querySelectorAll("img")
            )[0];
            const scaleX = 1;
            const scaleY = 1;
            const x = rect.x * scaleX;
            const y = rect.y * scaleY;
            const width = rect.width * scaleX;
            const height = rect.height * scaleY;
            floatingImage.style.top = `${y}%`;
            floatingImage.style.left = `${x}%`;
            floatingImage.style.width = `${width}%`;
            floatingImage.style.height = `${height}%`;
            // floatingImage.style.zIndex = 2;
          }
          this.showFloatingImage = true;
          this.showButtonFlag = false;
        } else {
          this.hideFloatingImage();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.layerTips {
  font-size: 8px;
  margin-top: 15px;
  color: red;
}

.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.avatar-uploaderImage ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploaderImage .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploaderImage-icon {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatarImage {
  width: 160px;
  height: 120px;
  display: block;
}

.avatar-uploaderVideo ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploaderVideo .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-iconVideo {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatarVideo {
  width: 150px;
  height: 120px;
  display: block;
}

.tabPaneOnetop {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tabPanOnebtm {
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    /* 设置拖动条颜色 */
  }

  ul {
    li {
      float: left;
      white-space: nowrap;
      display: block;
      margin-bottom: 5px;
    }
  }
}

// .tabPanOnebtm::-webkit-scrollbar {
//     width: 6px; /* 设置滚动条宽度 */
//     height: 10px; /* 设置滚动条高度 */
// }

.el-checkbox:last-of-type {
  margin-right: 3px;
}

.video {
  width: 240px;
  height: 130px;
  margin-right: 16px;
}

.tabPanTwobtm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  .vrImg {
    padding: 17px;
    margin-top: 10px;
    display: flex;
    margin-left: 30px;
    width: 300px;
    height: 180px;

    img {
      width: 75%;
      height: 100%;
    }
  }
}

.proImg {
  width: 80px;
  height: 80px;
}

.cabinetTop {
  width: 100%;
  height: 30px;
  display: flex;
}

.middelTop {
  display: flex;
  height: 30px;
  width: 100%;
  text-align: center;
  line-height: 30px;

  h2 {
    margin: 0;
    flex: 1;
  }

  .icon {
    display: flex;
    width: 28%;
    justify-content: space-between;

    span {
      flex: 1;
      font-size: 16px;
    }

    .btn {
      padding: 0;
    }

    i {
      font-size: 15px;
    }
  }
}

.cabinetMiddle {
  display: flex;
  position: relative;
  margin-top: 10px;

  .middleLeft {
    position: relative;
    width: 70%;
    height: 65%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 28%;
    height: 99%;
    display: flex;
    position: absolute;
    margin-left: 72%;

    .rightOne {
      width: 50%;
      height: 100%;
      position: relative;

      ul {
        position: absolute;
        float: left;
        width: 100%;
        height: 100%;
        margin: 0;

        li {
          width: 100%;
          height: 24%;
          display: block;

          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .rightTwo {
      width: 50%;
      height: 100%;
      position: relative;

      ul {
        position: absolute;
        float: left;
        width: 100%;
        height: 100%;
        margin: 0;

        li {
          position: relative;
          width: 100%;
          height: 24%;
          display: block;

          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }

          .detele {
            position: absolute;
            top: 5px;
            right: 10px;
            z-index: 9;

            .deleBtn {
              position: absolute;
              top: 3px;
              right: 0px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.cabinetBottom {
  width: 100%;
  height: 100px;
  margin-top: 15px;
  background-color: #e5e5e5;

  ul {
    // overflow-x: auto;
    // white-space:nowrap;
    // overflow-y: hidden;
    width: 100%;
    height: 100px;

    //   ul::-webkit-scrollbar {
    //     /* 设置滚动条宽度 */
    //     width: 100%;
    //     height: 20px;
    //     /* 设置滚动条背景色 */
    //     background-color: red;
    // }
    // ul::-webkit-scrollbar-thumb {
    //   /*滚动条里面小方块*/
    //   border-radius: 10px;
    //   height: 20px;
    //   width: 20px;
    //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   // background: #E5E5E5;
    //   background: red;
    // }
    li {
      // float:left;
      width: 160px;
      height: 90px;
      margin-right: 10px;
      white-space: nowrap;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// .cabinetBottom::-webkit-scrollbar {
//     /* 设置滚动条宽度 */
//     width: 100%;
//     height: 20px;
//     /* 设置滚动条背景色 */
//     background-color: red;
// }

.newInternalTop {
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: space-around;

  h2 {
    margin: 0;
  }

  .topThree {
    .btn {
      margin: 0;
      padding: 0 5px;
    }
  }
}

.interImage {
  position: relative;

  .interSmallImage {
    position: absolute;
  }
}

.newInternalMiddle {
  display: flex;
  justify-content: space-between;

  .middleLeft {
    position: relative;
    width: 82%;
    height: 81%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 13%;
    margin-left: 77%;
    display: flex;
    position: absolute;

    ul {
      li {
        position: relative;
        width: 125px;
        height: 95px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
        }

        .detele {
          position: absolute;
          top: 5px;
          right: 10px;
          // width: 20px;
          // height: 20px;
          // border-radius: 20px;
          // background-color: #ffffff;
          // text-align: center;
          // line-height: 20px;
          z-index: 9;

          .deleBtn {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 0;
          }
        }
      }
    }
  }
}

.internalTop {
  display: flex;
  height: 40px;
  line-height: 40px;

  h2 {
    flex: 1;
    margin: 0;
    text-align: center;
  }

  .el-button {
    margin: 0 5px;
    padding: 0;
  }
}

.internalMiddle {
  display: flex;
  height: 50%;

  .middleLeft {
    position: relative;
    width: 62.5%;
    height: -50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 33.5%;
    height: 100%;
    margin-left: 30px;

    .rightBox {
      width: 100%;
      height: 158px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.internalBottom {
  width: 100%;
  height: 100px;
  display: flex;

  .bottomBox {
    width: 100%;
    height: 100px;
    margin-top: 15px;
    background-color: #e5e5e5;

    ul {
      overflow: hidden;

      li {
        float: left;
        width: 160px;
        height: 90px;
        margin-right: 10px;
        white-space: nowrap;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.tabPanThreebtm {
  .tabPanUl {
    width: 100%;
    height: 100%;

    li {
      float: left;
      width: 150px;
      // height:100px;
      margin-left: 20px;
      white-space: nowrap;
      display: block;
      margin-bottom: 5px;

      img {
        width: 150px;
        height: 100px;
      }
    }
  }
}

.mainPublishDialog /deep/ .el-dialog__body {
  height: 70vh;
  overflow: scroll;
}

.mainPublishDialog /deep/ .el-dialog {
  margin-top: 7vh !important;
}

/deep/ .el-dialog__wrapper {
  overflow: hidden;
}

.tabsInput /deep/ .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 100% !important;
}

.tabsInput /deep/ .el-input__inner {
  width: 100% !important;
}

/deep/ .el-dialog {
  margin-top: 8vh !important;
}

/deep/ .el-dialog__body {
  max-height: 70vh !important;
  overflow-y: scroll !important;
}

/deep/ .upload-demo {
  display: inline-block;
}
</style>
